/**
 * Checks if the current breakpoint matches the given condition, e.g.
 * Capitan.Vars.currentBreakpoint is 'sm', then Capitan.Function.assertBreakpoint('lt', 'lg')
 * would return true
 *
 * @param {string} operator - lt (lower than), eq (equal), ht (higher than)
 * @param {string} breakpoint - xs, sm, md, lg, xl
 * @return {boolean} breakpoint condition
 */
import { getBreakpoint } from './get-breakpoint';

export function assertBreakpoint(operator: string, breakpoint: string): boolean {
  'use strict';

  let currentBreakpoint = getBreakpoint() as string;
  let breakpoints: string[] = Object.keys({ xs: 320, sm: 480, md: 768, lg: 992, xl: 1280, xxl: 1500 });
  let curBreakpointIndex = breakpoints.indexOf(currentBreakpoint);
  let conditionalBreakpointIndex = breakpoints.indexOf(breakpoint);

  switch (operator) {
    case 'eq':
      return curBreakpointIndex === conditionalBreakpointIndex;
    case 'lt':
      return curBreakpointIndex < conditionalBreakpointIndex;
    case 'ht':
      return curBreakpointIndex > conditionalBreakpointIndex;
    default:
      return false;
  }
}
