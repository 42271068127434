export function getBreakpoint(): string {
  'use strict';

  const windowWidth = window.innerWidth;
  const breakpoints: Record<string, number> = { xs: 320, sm: 480, md: 768, lg: 992, xl: 1280, xxl: 1500 };
  let breakpoint = '';

  for (let i = Object.keys(breakpoints).length - 1; i >= 0; i -= 1) {
    breakpoint = Object.keys(breakpoints)[i];

    if (windowWidth >= breakpoints[breakpoint]) {
      return breakpoint;
    } else if (i === 0 && windowWidth < breakpoints[breakpoint]) {
      return breakpoint;
    }
  }

  return breakpoint;
}
