const options = {
  errorMessages: {
    required: 'Dies ist ein Pflichtfeld.',
    invalid: 'Bitte überprüfen Sie Ihre Eingabe.',
    inValidMinValue: 'Der Mindestbetrag beträgt 5€.',
    inValidMaxValue: 'Der Höchstbetrag beträgt 15.000€.',
    inValidAmount: 'Bitte einen korrekten Betrag eingeben, z.B. 25 oder 100.',
    invalidPostcodeDE: 'Die Postleitzahl wird nach Auswahl des Landes überprüft. Bitte beachten Sie, dass Deutschland vorausgewählt ist.',
    invalidPostcodeAT: 'Die Postleitzahl wird nach Auswahl des Landes überprüft. Bitte beachten Sie, dass Österreich oder Schweiz vorausgewählt ist.'
  },
  validation: {
    validIcon: '<div class="is--valid-icon"><svg width="20px" height="20px" class="svg-icon svg-icon--donate-check"><use href="#donate-check"/></svg></div>',
    invalidIcon: '<div class="is--invalid-icon"><svg width="16px" height="16px" class="svg-icon svg-icon--donate-cross"><use href="#donate-cross"/></svg></div>'
  },
  selectors: {
    errorMsg: '.donation-form__errormsg',
    error: '.donation-form__error',
  },
  classes: {
    hasError: 'has-error'
  }
}

export function inlineValidation(event) {

  let element;
  if(event.currentTarget) {
    element = event.currentTarget;
  }
  else {
    element = event[0];
  }

  const isRequired = checkRequired(element);
  const wasValid = checkWasValid(element);
  const wasInvalid = checkWasInvalid(element);

  if (isRequired) {
    handleRequired(element, wasValid, wasInvalid);
  } else if (isPostCode(element)) {
    handlePostCode(element, wasValid, wasInvalid);
  } else if (isAmount(element)) {
    handleAmount(element, wasValid, wasInvalid);
  } else if (!isValid(element)) {
    handleInvalid(element, wasValid, wasInvalid);
  } else if (isPrivacyCheckbox(element)) {
    handlePrivacyCheckbox(element);
  } else if (isBankConfirmationCheckbox(element)) {
    handleBankConfirmationCheckbox(element);
  } else {
    handleValid(element, wasValid, wasInvalid);
  }
};

const checkRequired = (element) => {
  return (
    element.classList.contains('is--required') &&
    element.value === '' &&
    element.getAttribute('type') !== 'checkbox'
  );
};

const checkWasValid = (element) => {
  return element.previousElementSibling?.classList.contains('is--valid-icon');
};

const checkWasInvalid = (element) => {
  return element.previousElementSibling?.classList.contains('is--invalid-icon');
};

const insertValidationIcon = (element, icon) => {
  const fragment = document.createRange().createContextualFragment(icon);
  element.parentNode?.insertBefore(fragment, element);
};


const handleRequired = (element, wasValid, wasInvalid) => {
  element.classList.add(options.classes.hasError);
  const errorElement = element.parentNode?.querySelector(options.selectors.error)
  errorElement.textContent = options.errorMessages.required;

  if (wasValid || wasInvalid) {
    element.previousElementSibling?.remove();
  }

  if (element.getAttribute('type') !== 'checkbox') {
    insertValidationIcon(element, options.validation.invalidIcon);
  }

  if (element.getAttribute('type') === 'number') {
    errorElement.textContent = options.errorMessages.inValidAmount;
  }
};


// Check if postcode and handle validation
const isPostCode = (element) => {
  return element.getAttribute('id') === 'payment_post_code' && !RegExp(element.pattern).test(element.value);
};

const handlePostCode = (element, wasValid, wasInvalid) => {
  element.classList.add(options.classes.hasError);
  const errorMessage = element.nextElementSibling;

  if (element.pattern.includes('{5}')) {
    errorMessage.textContent = options.errorMessages.invalidPostcodeDE;
  } else if (element.pattern.includes('{4}')) {
    errorMessage.textContent = options.errorMessages.invalidPostcodeAT;
  } else {
    errorMessage.textContent = options.errorMessages.invalid;
  }

  if (wasValid || wasInvalid) {
    element.previousElementSibling?.remove();
  }

  insertValidationIcon(element, options.validation.invalidIcon);
};


// Check if amount and handle validation
const isAmount = (element) => {
  return element.getAttribute('id') === 'payment_amount';
};

const handleAmount = (element, wasValid, wasInvalid) => {
  let isValid = true;
  const value = element.value
  const regex = /^[0-9]*$/;
  const errorMessage = element.nextElementSibling;

  if (!regex.test(value)) {
    isValid = false;
    element.classList.add(options.classes.hasError);
    errorMessage.textContent = options.errorMessages.inValidAmount;

    if (wasValid || wasInvalid) {
      element.previousElementSibling?.remove();
    }

    insertValidationIcon(element, options.validation.invalidIcon)
  }

  if (isValid) {
    const value = parseInt(element.value);

    if (value > 15000) {
      isValid = false;
      element.classList.add(options.classes.hasError);
      errorMessage.textContent = options.errorMessages.inValidMaxValue;
      element.previousElementSibling?.remove();
      insertValidationIcon(element, options.validation.invalidIcon);
    }

    else if (value < 5) {
      isValid = false;
      element.classList.add(options.classes.hasError);
      errorMessage.textContent = options.errorMessages.inValidMinValue;
      element.previousElementSibling?.remove();
      insertValidationIcon(element, options.validation.invalidIcon);
    }

    else {
      element.classList.remove(options.classes.hasError);
      element.parentNode?.querySelector('label')?.classList.remove(options.classes.hasError);
      errorMessage.textContent = '';

      if (isValid && (wasValid || wasInvalid)) {
        element.previousElementSibling?.remove();
      }

      if (element.getAttribute('type') !== 'checkbox') {
        insertValidationIcon(element, options.validation.validIcon);
      }
    }
  }
};


// Check if input valid validity false and handle validation
const isValid = (element) => {
  return element.validity.valid;
};

const handleInvalid = (element, wasValid, wasInvalid) => {

  const errorMessage = element.nextElementSibling;

  if (element.getAttribute('id') === 'payment_is_privacy_accepted') {
    if (!(element).checked) {
      element.parentNode?.querySelector('label')?.classList.add(options.classes.hasError);
      errorMessage.textContent = options.errorMessages.required;
    } else {
      element.parentNode?.querySelector('label')?.classList.remove(options.classes.hasError);
      const errorElement = element.parentNode?.querySelector(options.selectors.error);
      errorElement.textContent = '';
    }
  } else {
    if (element.getAttribute('type') !== 'checkbox') {
      errorMessage.textContent = options.errorMessages.invalid;
    }
  }

  element.classList.add(options.classes.hasError);

  if (wasValid || wasInvalid) {
    element.previousElementSibling?.remove();
  }

  if (element.getAttribute('type') !== 'checkbox') {
    insertValidationIcon(element, options.validation.invalidIcon);
  }
};


// Check if privacy checkbox and handle validation
const isPrivacyCheckbox = (element) => {
  return element.getAttribute('id') === 'payment_is_privacy_accepted';
};

const handlePrivacyCheckbox = (element) => {
  const errorMessage = element.nextElementSibling;

  if (!element.checked) {
    element.parentNode?.querySelector('label')?.classList.add(options.classes.hasError);
    errorMessage.textContent = options.errorMessages.required;
  } else {
    element.classList.remove(options.classes.hasError);
    element.parentNode?.querySelector('label')?.classList.remove(options.classes.hasError);
    errorMessage.textContent = '';
  }
};

// Check if bank confirmation checkbox and handle validation
const isBankConfirmationCheckbox = (element) => {
  return element.getAttribute('id') === 'payment_bank_confirmation';
};

const handleBankConfirmationCheckbox = (element) => {
  const errorMessage = element.nextElementSibling;

  if (!element.checked) {
    element.parentNode?.querySelector('label')?.classList.add(options.classes.hasError);
    errorMessage.textContent = options.errorMessages.required;
  } else {
    element.parentNode?.querySelector('label')?.classList.remove(options.classes.hasError);
    errorMessage.textContent = '';
  }
};

// Check if input valid validity true and handle validation
const handleValid = (element, wasValid, wasInvalid) => {
  const errorMessage = element.parentNode?.querySelector(options.selectors.error);

  element.classList.remove(options.classes.hasError);
  element.parentNode?.querySelector('label')?.classList.remove(options.classes.hasError);
  errorMessage.textContent = '';

  if (wasValid || wasInvalid) {
    element.previousElementSibling?.remove();
  }

  if (element.getAttribute('type') !== 'checkbox') {
    insertValidationIcon(element, options.validation.validIcon);
  }
};
