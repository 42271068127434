(function () {
  document.querySelectorAll<HTMLElement>('[data-event=true]').forEach(function(element) {
    element.addEventListener('click', function () {

        var category = element.dataset.category;

        var action = element.dataset.action;

        var label = element.dataset.label;

        var thisEventValue = element.dataset.eventValue
            ? element.dataset.eventValue
            : 'undefined';

        window.dataLayer.push({
            event: 'GAEvent',
            eventCategory: category,
            eventAction: action,
            eventLabel: label,
            eventValue: thisEventValue
        });
    });
});


  // remove line breaks and whitespaces from given string
  const GA_removeWhitespace = (string: string) => {
      return string.replace(/[\n\r]+|[\s]{2,}/g, ' ').trim();
  }

  // determine section where the button ist placed
  const GA_getSection = (el:HTMLElement) => {
      const sections = {
          Header: el.closest('header'),
          Body: el.closest('main'),
          Footer: el.closest('footer')
      };
      const section = Object.entries(sections).filter(section => section[1] !== null);
      if (section.length == 0) {
          return 'Body';
      }

      if (!section[0][0]) {
          return false;
      }
      return section[0][0];
  }

  // determine content_name (stage title or title tag)
  const GA_getPageTitle = () => {
      const pageTitle = document.querySelector('head title')?.textContent;
      const stageActiveSlide = document.querySelector('.stage .stage__slide.is-active');
      if (!stageActiveSlide) {
          return pageTitle;
      }
      const stageHeadline = stageActiveSlide.querySelector('.stage__slide-headline');
      if (!stageHeadline) {
          return pageTitle;
      }

      const stageHeadlineParts = [...stageHeadline.querySelectorAll('span')].map(span => span.textContent);
      return stageHeadlineParts.join(' ');
  }

  // determine the level of the clicked nav link
  const GA_getNavLevel = (el:HTMLElement) => {
      const isThirdLevel = el.classList.contains('nav-main__third-level-link');
      const isSecondLevel = el.classList.contains('nav-main__sub-link') || el.closest('.footer__sub-nav');

      if (isThirdLevel) {
          return 'Third Level';
      }

      if (isSecondLevel) {
          return 'Second Level';
      }

      return 'First Level';
  }

  // handle mailto: and tel: links
  const handleMailtoAndTelLinks = () => {
      const elements = document.querySelectorAll<HTMLAnchorElement>('a[href^="mailto:"], a[href^="tel:"]');
      if (elements.length == 0) {
          return;
      }

      elements.forEach(el => {
          el.addEventListener('click', () => {
              const contactType = el.href.indexOf('mailto') >= 0 ? 'E-Mail' : 'Anruf';
              const pageSection = GA_getSection(el);

              const dataLayerObj = {
                  'event': 'ga_event',
                  'event_category': 'click_contact',
                  'click_text': GA_removeWhitespace(el.textContent?el.textContent:''),
                  'contact_type': contactType,
                  'page_section': pageSection
              };

              window.dataLayer.push(dataLayerObj);
          });
      })
  };

  // handle _all_ red cta "Jetzt Spenden" donation buttons
  const handleDonationButtons = () => {
      const elements = document.querySelectorAll<HTMLAnchorElement>('.js-donate-button, .header__btn, a[href*="/spenden/"]');

      if (elements.length == 0) {
          return;
      }

      elements.forEach(el => {
          el.addEventListener('click', () => {
              const isHeaderButton = el.classList.contains('header__btn') || el.closest('.donate--box');
              const contentName = GA_getPageTitle();
              const dataLayerObj = {
                  'event': 'ga_event',
                  'event_category': 'click_donationbutton',
                  'click_text': GA_removeWhitespace(el.textContent?el.textContent:''),
                  'click_url': el.href,
                  'content_name': isHeaderButton ? 'Header' : contentName
                  /* bitte bei Klick auf den Spendenbutton rechts im Header 'content_name': 'Header' hinterlegen,
                  Klicks auf die roten "Jetzt spenden"-Buttons unter den rotierenden Bannern auf der Startseite sollen
                  als content_name bitte jeweils die Caption + Headline des Banners übergeben bekommen */
              };
              window.dataLayer.push(dataLayerObj);
          });
      })
  };

  // handle all navigation links in header and footer
  const handleNavigation = () => {
      const elements = document.querySelectorAll<HTMLAnchorElement>('.nav-main a, .footer__nav a');
      if (elements.length == 0) {
          return;
      }

      elements.forEach(el => {
          el.addEventListener('click', () => {
              const dataLayerObj = {
                  'event': 'ga_event',
                  'event_category': 'click_navigation',
                  'click_text': GA_removeWhitespace(el.textContent?el.textContent:''),
                  'click_url': el.href,
                  'page_section': GA_getSection(el),
                  'click_level': GA_getNavLevel(el)
              };
              window.dataLayer.push(dataLayerObj);
          })
      });
  }

  // handle all clicks on news teaser / links to news
  const handleNewsLinks = () => {
      const elements = document.querySelectorAll<HTMLAnchorElement>('a[href*="/news/"]');
      if (elements.length == 0) {
          return;
      }

      elements.forEach(el => {
          el.addEventListener('click', () => {
              let elText = GA_removeWhitespace(el.textContent?el.textContent:'');

              // if the link is within a news-slider (e.g. on the frontpage)
              // get the headlines text of the slider item
              if (isSlideritem(el)) {
                  const textEl = el.nextElementSibling?.querySelector('.teaser__headline');
                  elText = GA_removeWhitespace(textEl?.textContent?textEl.textContent:'');
              }
              const dataLayerObj = {
                  'event': 'ga_event',
                  'event_category': 'click_news',
                  'click_text': elText,
                  'click_url': el.href
              };
              window.dataLayer.push(dataLayerObj);
          })
      });
  }

  // determine if current newslink is a teaser on the frontpage news slider
  const isSlideritem = (el:HTMLElement) => {
      const slideEl = el.closest('.splide__slide');
      if (slideEl) {
          return true;
      }
      return false;
  }

  // handle all clicks on social media links
  const handleSocialMediaLinks = () => {
      const elements = document.querySelectorAll<HTMLAnchorElement>('.footer__social-item a');
      if (elements.length == 0) {
          return;
      }

      elements.forEach(el => {
          el.addEventListener('click', () => {
              const click_text = el.dataset.label?.substr(3);
              var click_text_first_uc=''
              if (click_text){
                click_text_first_uc = click_text?.charAt(0).toUpperCase() + click_text?.slice(1);
              }

              const dataLayerObj = {
                  'event': 'ga_event',
                  'event_category': 'click_social_buttons',
                  'click_text': click_text_first_uc,
                  'click_url': el.href
              };
              window.dataLayer.push(dataLayerObj);
          })
      });
  }

  // handle all clicks on red cta buttons except donation buttons
  const handleCtaLinks = () => {
      const elements = [...document.querySelectorAll<HTMLAnchorElement>('.btn--teaser')].filter(el => GA_removeWhitespace(el.textContent?el.textContent:'') != 'Jetzt spenden');
      if (elements.length == 0) {
          return;
      }

      elements.forEach(el => {
          el.addEventListener('click', () => {
              const dataLayerObj = {
                  'event': 'ga_event',
                  'event_category': 'click_teaser_text',
                  'click_text': GA_removeWhitespace(el.textContent?el.textContent:''),
                  'click_url': el.href,
                  'content_name': GA_getPageTitle()
              };
              window.dataLayer.push(dataLayerObj);
          })
      });
  }

  // handle all form submits except donation form and newsletter subscriptions
  const handleForms = () => {
      const elements = [...document.querySelectorAll('.form')].filter(el => {

          // no donation form
          if (!el.classList.contains('.donation-form--findock')|| !el.classList.contains('.donation__form__single')) {

              // no newsletter form
              if (!el.closest('#newsletterForm')) {
                  return true;
              }
          }

          return false;
      });

      if (elements.length == 0) {
          return;
      }

      elements.forEach(el => {
          el.addEventListener('submit', () => {
              const dataLayerObj = {
                  'event': 'ga_event',
                  'event_category': 'form_submit',
                  'form_type': el.id
              };
              window.dataLayer.push(dataLayerObj);
          })
      });
  }

  const handleNewsletterForm = () => {
      const elements = document.querySelectorAll('#newsletterForm form, .newsletter-registration form');

      if (elements.length == 0) {
          return;
      }

      elements.forEach(el => {
          el.addEventListener('submit', () => {

              const dataLayerObj = {
                  'event': 'ga_event',
                  'event_category': 'newsletter_subscription',
              };
              window.dataLayer.push(dataLayerObj);
          })
      })

  }

  handleMailtoAndTelLinks(); // click_contact
  handleDonationButtons(); // click_donationbutton
  handleNavigation(); // click_navigation
  handleNewsLinks(); // click_news
  handleSocialMediaLinks(); // click_social_buttons
  handleCtaLinks(); // click_teaser_text
  handleForms(); // form_submit
  handleNewsletterForm(); // newsletter_subscription
})();
