(function (window, document, undefined) {
  'use strict';

  //plugin global / shared variables
  const pluginName = 'validate';
  const rAF = window.requestAnimationFrame || window.setTimeout;

  //option defaults
  const defaults = {
    disableAutocompletion: false,
    disableSubmitButtons: false,
    sendFormViaAjax: false,

    errorMessages: {
      defaultMessage: 'Bitte geben Sie einen gültigen Wert ein.',
      badInput: 'Bitte geben Sie einen gültigen Wert ein.',
      customError: 'Bitte geben Sie einen gültigen Wert ein.',
      patternMismatch: 'Bitte geben Sie einen gültigen Wert ein.',
      rangeOverflow: 'Bitte geben Sie einen gültigen Wert ein.',
      rangeUnderflow: 'Bitte geben Sie einen gültigen Wert ein.',
      stepMismatch: 'Bitte geben Sie einen gültigen Wert ein.',
      tooLong: 'Bitte geben Sie einen gültigen Wert ein.',
      tooShort: 'Bitte geben Sie einen gültigen Wert ein.',
      typeMismatch: 'Bitte geben Sie einen gültigen Wert ein.',
      valueMissing: 'Bitte geben Sie einen gültigen Wert ein.'
    },

    selectors: {
      inputBox: '.form__field-wrapper',
      inputErrorBox: '.form__error',
      errorMessages: 'small' //in inputErrorBox
    },

    classes: {
      userError: 'error',
      valid: 'is-valid',
      invalid: 'is-invalid',
      disabled: 'is-disabled',
      fallbackMessage: 'is-fallback-message'
    },

    callbacks: {
      init: function (data) { },
      willDestroy: function (data) { },
      afterValidation: function (data) { }
    },

    markup: {
      inputErrorBox: '<div class="form__error"></div>',
      errorMessage: '<small></small>'
    }
  };

  // default plugin constructor
  function Plugin(element, options) {
    this.element = element;
    this.el = this.element;
    this.options = options || defaults;
    this.init(options);
  }

  // plugin implementation
  Plugin.prototype = {
    // initialization logic
    init: function (options) {
      // instance properties
      this.id = this.el.id || this.getId();

      // main DOM elements
      this.queryElements();

      //Check for validation errors
      this.checkForErrors();

      //initial calls
      this.disableDefaultValidation();
      this.bindEvents();

      window.validationInstance = this;
      window.validationForm = this.element;

      // call callback from options
      this.callback('init');
    },

    isValid: function (inputElement) {
      return inputElement.checkValidity();
    },

    /**
     * Query important component elements from the DOM
     *
     * @private
     */
    queryElements: function () {
      // find the form element
      this.form = this.el.tagName === 'FORM' ? this.el : this.el.querySelector('form');

      //get all input elements (select etc. needed to be added)
      this.inputElements = this.el.querySelectorAll('input, select, textarea');

      //get the input and error wrappers
      this.inputBoxes = this.el.querySelectorAll('.form__field-wrapper');
      this.inputErrorBoxes = this.getOrCreateErrorBoxes();
      // filter the submit buttons from the forms elements
      this.submitButtons = Array.from(this.form.elements).filter(function (element) {
        return element.type === 'submit';
      });
    },

    // Get or create error boxes for input elements
    getOrCreateErrorBoxes: function () {
      const _this = this;
      return Array.from(this.inputBoxes).map(function (el) {
        const inputErrorBox = el.querySelector('.form__error') || _this.createErrorBox(el);
        // Check if the element is not null before accessing its dataset
        if (inputErrorBox) {
          let dataErrorMessage = '';
          let $errorMessages = el.querySelectorAll('small');
          if ($errorMessages.length === 0 && (dataErrorMessage = el.querySelector('[data-error-message]')?.dataset.errorMessage)) {
            let errorMessage = _this.createErrorMessage();
            errorMessage.textContent = dataErrorMessage;
            inputErrorBox.appendChild(errorMessage);
          }
        }
        return inputErrorBox;
      });
    },

    // Create an error message element
    createErrorMessage: function () {
      const errorMessage = document.createElement('small');
      return errorMessage;
    },

    // Create an error box element
    createErrorBox: function (inputBox) {
      const errorBox = document.createElement('div');
      errorBox.className = 'form__error';
      inputBox.appendChild(errorBox);
      return errorBox;
    },

    // Get invalid inputs
    getInvalidInputs: function () {
      let _this = this;
      return Array.from(this.inputElements).filter(function (element) {
        return !_this.isValid(element);
      });
    },

    // bind essential events for the component
    bindEvents: function () {
      let _this = this;
      Array.from(this.inputElements).forEach(function (element) {
        element.addEventListener('focusin', function (e) {
          _this.setInputData(e.target, {
            userInteracted: true
          });
        });

        element.addEventListener('input', function (e) {
          _this.checkValidityStates(e.target, e);
        });
      });

      this.form.addEventListener('reset', function (e) {
        _this.setInputData(_this.inputElements, {
          userInteracted: false
        });
        //needs to be checked, after initial values are restored
        setTimeout(function () {
          _this.checkValidityStates(_this.inputElements, e);
        });
      });

      this.form.addEventListener('submit', function (e) {
        _this.checkValidityStates(_this.inputElements, e);
        _this.userSubmitIntent(e);
      });
    },

    // Check for validation errors
    checkForErrors: function () {
      this.firstInvalidElement = this.form.querySelector('.is-invalid');
      if (this.firstInvalidElement) {
        const headerHeight = document.querySelector('.header').offsetHeight || 0;
        window.scrollTo({
          top: this.firstInvalidElement.offsetTop - headerHeight - 25,
          behavior: 'smooth'
        });
      }
    },

    // Handle user submit intent
    userSubmitIntent: function (e) {
      const formValid = this.form.checkValidity();
      if (this.options.sendFormViaAjax) {
        e.preventDefault();
        if (formValid) {
          this.callback('afterValidation', { validation: true });
        } else {
          this.callback('afterValidation', { validation: false });
        }
      } else {
        !formValid && e.preventDefault();
      }
      this.checkValidityStates(this.inputElements, e);
      this.getInvalidInputs()[0].focus();
    },

    // Set input data
    setInputData: function (inputElement, setData) {
      const _this = this;
      if (!inputElement || (inputElement.length > 1 && !inputElement.tagName)) {
        inputElement = inputElement || this.inputElements;
        Array.from(inputElement).forEach(function (el) {
          _this.setInputData(el, setData);
        });
        return;
      }
      const currentInputData = inputElement.dataset[this.id] ? JSON.parse(inputElement.dataset[this.id]) : {};
      inputElement.dataset.validationData = JSON.stringify(Object.assign(currentInputData, setData));
    },

    // Check validity states of input elements
    checkValidityStates: function (inputElement, e) {
      const _this = this;
      if (!inputElement || (inputElement.length > 1 && !inputElement.tagName)) {
        const inputEls = inputElement || this.inputElements
        Array.from(inputEls).forEach(function (el) {
          _this.checkValidityStates(el, e);
        });
        return;
      }
      inputElement = inputElement;
      const isValid = inputElement.checkValidity();
      if ((!e || e.type === 'input') && !isValid) {
        return;
      }
      rAF(function () {
        _this.updateValidationUI(inputElement, e);
      });
    },

    // Update validation user interface
    updateValidationUI: function (inputElement, e) {
      const _this = this;
      if (!inputElement || (inputElement.length > 1 && !inputElement.tagName)) {
        const inputEls = inputElement || _this.inputElements
        Array.from(inputEls).forEach(function (el) {
          _this.checkValidityStates(el, e);
        });
        return;
      }

      const $inputElement = inputElement;
      const $inputBox = $inputElement.closest(_this.options.selectors.inputBox);
      if ($inputBox) {
        let $inputErrorBox = $inputBox.querySelector(_this.options.selectors.inputErrorBox);
        const validityState = inputElement.validity;
        const isValid = validityState.valid;
        if ((!e || e.type === 'input') && !isValid) {
          return;
        }
        rAF(function () {
          _this.updateInputErrorBox($inputErrorBox, Object.assign(validityState, {
            validationMessage: inputElement.validationMessage
          }));
          _this.updateElementClasses($inputBox, $inputElement, isValid);
        });
      }
    },

    // Update error box with validation information
    updateInputErrorBox: function (inputErrorBox, validityState) {
      const _this = this;
      if (!inputErrorBox) {
        inputErrorBox = _this.createErrorBox(_this.inputBox);
      }

      const $inputErrorBox = inputErrorBox;
      let $errorMessages = $inputErrorBox.querySelectorAll(_this.options.selectors.errorMessages);
      let groupedValidityStates = _this.getValidationStateGrouped(validityState);
      let errorMessagesToShow = Array.from($errorMessages).filter(function (err) {
        let errorMessageTypes = err.dataset.errorTypes ? err.dataset.errorTypes.split(' ') : [];
        return errorMessageTypes.some(function (errorMessageType) {
          return groupedValidityStates.activeStates.indexOf(errorMessageType) !== -1;
        });
      });

      if (!validityState.valid && errorMessagesToShow.length === 0) {
        errorMessagesToShow = [$errorMessages[$errorMessages.length - 1]];

        if (errorMessagesToShow.length === 0 || errorMessagesToShow[0]?.classList.contains(_this.options.classes.fallbackMessage)) {
          let firstErrorType = groupedValidityStates.activeStates[0];
          errorMessagesToShow = [document.createElement('small')];
          errorMessagesToShow[0].dataset.errorTypes = firstErrorType;
          errorMessagesToShow[0].classList.add(_this.options.classes.fallbackMessage);
          errorMessagesToShow[0].textContent = _this.options.errorMessages[firstErrorType] || _this.options.errorMessages.defaultMessage;
          $inputErrorBox.appendChild(errorMessagesToShow[0]);
        }
      }

      Array.from($errorMessages).forEach(function (error) {
        if (error) {
          error.hidden = true;
        }
      });

      if (errorMessagesToShow[0]) {
        errorMessagesToShow[0].hidden = false;
        $inputErrorBox.dataset.currentErrorTypes = groupedValidityStates.activeStates.join(' ');
      }

      $inputErrorBox.hidden = validityState.valid;
    },

    updateElementClasses: function (inputBox, inputElement, isValid) {
      if (isValid) {
        inputBox.classList.remove(this.options.classes.invalid);
        inputBox.classList.add(this.options.classes.valid);
        inputElement.classList.remove(this.options.classes.userError);
      } else {
        inputBox.classList.remove(this.options.classes.valid);
        inputBox.classList.add(this.options.classes.invalid);
        inputElement.classList.add(this.options.classes.userError);
      }
    },

    // Get grouped validation states
    getValidationStateGrouped: function (validityState) {
      let groupedValidityStates = {
        valid: validityState.valid,
        activeStates: [],
        inactiveStates: []
      };
      for (let validityError in validityState) {
        if (validityState[validityError]) {
          groupedValidityStates.activeStates.push(validityError);
        } else {
          groupedValidityStates.inactiveStates.push(validityError);
        }
      }
      return groupedValidityStates;
    },

    // Disable the default validation
    disableDefaultValidation: function () {
      this.form.noValidate = true;
      // Check if this.options is defined before accessing its properties
      if (this.options) {
        if (this.options.disableAutocompletion) {
          this.form.autocomplete = 'off';
        }
      }
    },

    // Update the state of submit buttons
    updateSubmitButtons: function () {
      let formValid = this.form.checkValidity();
      if (this.options.disableSubmitButtons) {
        this.submitButtons.forEach(function (button) {
          button.disabled = !formValid;
        });
      }
      this.submitButtons.forEach(function (button) {
        button.classList.toggle(this.options.classes.disabled, !formValid);
      });
    },

    // namespaces the event names (whitespace seperated)
    nsEvent: function (events) {
      if (!events) {
        console.error('_namespacedEvents | events parameter was empty');
      }
      return events.split(' ').map(function (eventName) {
        return eventName + '.' + this.id;
      }).join(' ');
    },

    // call callbacks from the options
    callback: function (callbackName, data) {
      if (!(callbackName in this.options.callbacks)) {
        console.error(pluginName, 'illegal callback name', callbackName);
      }
      return this.options.callbacks[callbackName](data);
    },

    destroy: function () {
      this.callback('willDestroy');
    },

    //generates a random UUID in plugin namespace
    getId: function (subNamespace) {
      const namespace = subNamespace ? pluginName + '_' + subNamespace : pluginName;
      let s4 = function () {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
      };
      return [namespace, s4(), s4(), s4(), s4()].join('-');
    }
  };


  // lightweight plugin wrapper around the constructor, preventing against multiple instantiations
  window[pluginName] = function (element, options) {
    //check if plugin was already initialized on this element
    let instance = new Plugin(element, options);
    if (!element[pluginName]) {
      // initialize plugin on this element and save reference to instance in data
      element[pluginName] = instance;
    }
  };
})(window, document);
