/*
** -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
* Functions for checking usercentrics consent and setting facebook pixel
** -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
*/

const selectors = {
  facebookPixel: '#payment_person_custom_field_10323',
  facebookCustomAudiences: '#payment_person_custom_field_10324'
}

export function checkUserCentrics() {

  window.addEventListener('onConsentStatusChange', (event) => statusChange(event));

  if ('undefined' === typeof window.UC_UI || false === window.UC_UI.isInitialized()) {
      window.addEventListener(
          'UC_UI_INITIALIZED',
          function() {
              const services = window.UC_UI.getServicesBaseInfo();
              for (var i = 0; i < services.length; ++i) {
                  if ('Facebook Pixel' === services[i].name && 'Facebook Custom Audiences' === services[i].name) {
                      if (true === services[i].consent.status) {
                          console.log('FB Pixel + Custom Audiences has consent (via browser-ui api or event)');
                          setFbInputs(selectors.facebookPixel, selectors.facebookCustomAudiences, 1)
                      } else {
                          console.log('FB Pixel + Custom Audiences has no consent (via browser-ui api or event)');
                          setFbInputs(selectors.facebookPixel, selectors.facebookCustomAudiences, 0)
                      }
                      break;
                  }
              }
          }
      );
  } else {
      const services = window.UC_UI.getServicesBaseInfo();
      for (var i = 0; i < services.length; ++i) {
          if ('Facebook Pixel' === services[i].name && 'Facebook Custom Audiences' === services[i].name) {
              if (true === services[i].consent.status) {
                  console.log('FB Pixel + Custom Audiences has consent (via browser-ui api or event)');
                  setFbInputs(selectors.facebookPixel, selectors.facebookCustomAudiences, 1)
              } else {
                  console.log('FB Pixel + Custom Audiences has no consent (via browser-ui api or event)');
                  setFbInputs(selectors.facebookPixel, selectors.facebookCustomAudiences, 0)
              }
              break;
          }
      }
  }
}

function statusChange(e) {
  if(e.detail && e.detail.event === 'consent_status') {
    console.log('onConsentStatusChange (fb pixel)', e);
    if(e.detail['Facebook Pixel'] === true && e.detail['Facebook Custom Audiences'] === true) {
        console.log('FB Pixel + Custom Audiences has consent (via consent-status-event)');
        setFbInputs(selectors.facebookPixel, selectors.facebookCustomAudiences, 1)
    } else {
        console.log('FB Pixel + Custom Audiences has no consent (via consent-status-event)');
        setFbInputs(selectors.facebookPixel, selectors.facebookCustomAudiences, 0)
    }
  }
}

function setFbInputs(facebookPixel, facebookCustomAudiences, value) {
  const fbPixel = document.querySelector(facebookPixel);
  const fbCustomAudiences = document.querySelector(facebookCustomAudiences);
  fbPixel.value = value;
  fbCustomAudiences.value = value;
}
