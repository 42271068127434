/*
** -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
* Functions for pushing objects to window.dataLayer
** -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
*/

const selectors = {
  amountInput: '#payment_amount',
  purposeSelect: '#payment_fb_item_id'
}

export function pushDataLayer(event, interval, payment_method) {

  if (!event) {
    return;
  }

  const amountInput = document.querySelector(selectors.amountInput);
  const purposeSelect = document.querySelector(selectors.purposeSelect);
  const purposeOptions = purposeSelect?.querySelectorAll('option');


  const purpose = purposeOptions[purposeSelect?.selectedIndex].textContent;
  const dataLayerObj = {
    'event': event,
    'ecommerce': {
      'currency': 'EUR',
      'value': amountInput?.value,
      'items': [{
        'item_id': purposeSelect?.value, // SKU
        'item_name': purpose,
        'currency': 'EUR',
        'item_brand': interval == 0 ? 'Einmalspende' : 'Dauerspende',
        'price': amountInput?.value,
        'quantity': interval == 0 ? 1 : 12
      }]
    }
  }
  if (payment_method) {
    dataLayerObj.ecommerce.payment_type = payment_method;
  }
  window.dataLayer.push(dataLayerObj);
}
