import { assertBreakpoint } from './assert-breakpoints';
import { getBreakpoint } from './get-breakpoint';

export function backgroundResizer() {

  const elements = document.querySelectorAll('[data-bg]') as NodeListOf<HTMLElement>;

  if (elements.length === 0) {
    return;
  }

  setBackgroundImage(elements);

  window.addEventListener('resize', () => {
    setBackgroundImage(elements);
  });
}

function setBackgroundImage(elements: NodeListOf<HTMLElement>) {
  elements.forEach((element) => {
    let bgSrc;

    if (getBreakpoint() == 'sm') {
      bgSrc = element.getAttribute('data-bg');
    } else if (assertBreakpoint('lt', 'md') && typeof element.getAttribute('data-bgsm') != 'undefined') {
      bgSrc = element.getAttribute('data-bgsm');
    } else if (assertBreakpoint('lt', 'lg') && typeof element.getAttribute('data-bgmd') != 'undefined') {
      bgSrc = element.getAttribute('data-bgmd');
    } else if (assertBreakpoint('lt', 'xl') && typeof element.getAttribute('data-bglg') != 'undefined') {
      bgSrc = element.getAttribute('data-bglg');
    } else if (assertBreakpoint('lt', 'xxl') && typeof element.getAttribute('data-bgxl') != 'undefined') {
      bgSrc = element.getAttribute('data-bgxl');
    } else if (getBreakpoint() == 'xxl' && typeof element.getAttribute('data-bgxxl') != 'undefined') {
      bgSrc = element.getAttribute('data-bgxxl');
    }

    element.style.backgroundImage = 'url(' + bgSrc + ')';
  });
}

backgroundResizer();
