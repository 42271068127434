export default class DonationFormFRBTYPage {
  constructor(element, options) {
    this.element = element;

    this.options = DonationFormFRBTYPage.defaultOptions;
    process.env.NODE_ENV === 'development' &&
      console.info(`Init: ${this.options.name}`);

    this.init(this.element);
  }

  init() {
    if (this.element) {
      const o = DonationFormFRBTYPage.defaultOptions;
      const donationFormThankyouPage = $(o.selectors.donationForm);

      this.initForm(donationFormThankyouPage);
      this.trackData();

      return true;
    }
    return false;
  }

  trackData() {
    console.log('tracking data')
    const isThankPage = $('.frb-thank-page').length;
    const url = window.location.href; //+ '?produktname=1&summe=16&orderid=FB-T-4251332&kategorie=3&foerderart=allgemein_1';

    if (isThankPage && url.includes('?')) {
      let interval = this.getURLParameterValues('produktname');
      const amount = this.getURLParameterValues('summe');
      const transactionId = this.getURLParameterValues('transactionid');
      let category = this.getURLParameterValues('kategorie');
      const projectName = this.getURLParameterValues('foerderart');
      // const formType = _.getURLParameterValues('ftype');
      const formType = 'FRB Formular';

      //Commenting out formtype to check the Paypal error on successurl
      // if (formType == 0) {
      //     formType = 'Singlestep Formular'
      // } else if (formType == 1) {
      //     formType = 'Multistep Formular';
      // }

      // Handle interval
      switch (interval) {
        case '0':
          interval = 'Einmalspende';
          break;
        case '1':
          interval = 'Dauerspende';
          break;
      }

      // Handle category
      switch (category) {
        case '3':
          category = 'PayPal';
          break;
        case '7':
          category = 'Stripe Kreditkarte';
          break;
        case '8':
          category = 'Manuelle SEPA Lastschrift';
          break;
        case '13':
          category = 'Stripe Kreditkarte';
          break;
      }

      const transactions = JSON.parse(
        sessionStorage.getItem('STC_transactionsComplete'),
      );

      //Debug tracking data
      console.log(transactions);
      console.log(
          'formType: ' + formType + '\r \n' +
          'transactionId: ' + transactionId + '\r \n' +
          'transactionTotal: ' + amount + '\r \n' +
          'sku: ' + projectName + '\r \n' +
          'name: ' + interval + '\r \n' +
          'category: ' + category + '\r \n'
      )

      // Send data if not already done
      if (transactions === null || transactions.indexOf(transactionId) === -1) {
        if (transactionId && amount && projectName && interval && category) {
          window.dataLayer.push({
            event: 'purchase',
            ecommerce: {
              currency: 'EUR',
              value: amount,
              items: [
                {
                  item_id: projectName, // SKU
                  item_name: `${projectName} ${interval}`,
                  currency: 'EUR',
                  item_brand: interval,
                  price: amount,
                  quantity: interval == 'Einmalspende' ? 1 : 12,
                },
              ],
            },
            transaction_id: transactionId,
            payment_type: category,
          });

          // Set cookie to not track transaction twice
          let completedTransactions = transactions === null ? [] : transactions;
          completedTransactions.push(transactionId);
          sessionStorage.setItem(
            'STC_transactionsComplete',
            JSON.stringify(completedTransactions),
          );
        }
      }
    }
  }

  getURLParameterValues(parameterName, url) {
    if (!url) url = window.location.href;
    parameterName = parameterName.replace(/[\[\]]/g, '\\$&');
    const regularExpression = new RegExp(
        '[?&]' + parameterName + '(=([^&#]*)|&|#|$)',
      ),
      results = regularExpression.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  initForm(donationFormThankyouPage) {
    const o = DonationFormFRBTYPage.defaultOptions;
    const self = this;


    if (donationFormThankyouPage != null) {
      donationFormThankyouPage.on('fundraisingBox:init', function () {
        const projectID = self.getURLParameterValues('project_id');

        donationFormThankyouPage.getItems().filter(function (item) {
          if (item.fb_project_id == projectID) {
            if ($(o.selectors.description).text().length == 0) {
              $(o.selectors.description).html(item.title);
            }
          }
        });

        if (
          donationFormThankyouPage.getTransaction().first_name === null ||
          donationFormThankyouPage.getTransaction().last_name === null ||
          donationFormThankyouPage.getTransaction().first_name === '' ||
          donationFormThankyouPage.getTransaction().last_name === ''
        ) {
          if ($(o.selectors.headline).text().indexOf('first_name') > -1) {
            const cutText = $(o.selectors.headline).text().split('{');
            $(o.selectors.headline).text(cutText[0]);
          }
        }
      });
    }

    this.showHeadline();
  }

  randomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  initParticles() {
    const o = DonationFormFRBTYPage.defaultOptions;
    const heartcount = ($(o.selectors.headline).width() / 50) * 5;
    for (let i = 0; i <= heartcount; i++) {
        const size = (this.randomNumber(60, 120) / 10);

        $(o.selectors.headline).append(
            '<span class="particle" style="top:' + this.randomNumber(20, 80) +
            '%; left:' + this.randomNumber(0, 95) +
            '%;width:' + size +
            'px; height:' + size + 'px;animation-delay: ' +
            (this.randomNumber(0, 30) / 10) + 's;"></span>');
    }
  }

  showHeadline() {
    const o = DonationFormFRBTYPage.defaultOptions;
    const headline = $(o.selectors.headline);
    const self = this;

    setTimeout(function () {
      headline.addClass(o.classes.isVisible);
      self.initParticles();
    }, 1000);
  }
}

DonationFormFRBTYPage.defaultOptions = {
  componentSelector: '.frb-thank-page',
  name: 'DonationFormFRBThankyouPage',
  selectors: {
    headline: '.js-animate-hearts',
    donationForm: '.donation-form--frb__thankyou-page',
    description: '.donation-form__projectdescription',
  },
  classes: {
    isVisible: 'is-visible',
  },
};

document.querySelectorAll('.frb-thank-page').forEach((item) => {
  new DonationFormFRBTYPage(item);
});
